import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'
import { RadioButton, RadioButtonGroup } from 'material-ui/RadioButton'
import { map, F, pathOr, toLower } from 'ramda'
import { getStyle } from '../utils/liinilaevadThemeUtils'
import { getBluewaveStyle, getMaritimeStyle, isMaritime } from '../utils/maritimeStyleUtils'
import moduleStyles from '../modules/Modal/Modals.module.scss'
import { isMobile } from '../modules/Common/components/AppBar'

const baseRadioButtonStyles = {
  boxSizing: 'border-box',
  width: '20px',
  height: '20px',
  margin: '2px',
  backgroundColor: '#fbfbfb',
  border: getMaritimeStyle('2px solid #B7B7B7', 'solid 1px #dddddd'),
  borderRadius: '50%',
}

const style = getComputedStyle(document.body)

const colorSecondaryBase = style.getPropertyValue('--color-secondary-base')
const fontSizeStrong = style.getPropertyValue('--fs-base-strong')

const styles = {
  buttonGroupRoot: {
    width: '100%',
    padding: getMaritimeStyle('0', '0 10px 0 45px'),
  },
  radioButton: {
    marginTop: 10,
  },
  radioButtonIcon: {
    ...baseRadioButtonStyles,
  },
  radioButtonIconChecked: {
    ...baseRadioButtonStyles,
    border: getMaritimeStyle(
      `solid 5px ${colorSecondaryBase}`,
      getStyle(`solid 5px ${colorSecondaryBase}`, `solid 5px ${colorSecondaryBase}`)
    ),
  },
}

export const CheckedRadioButton = <div style={styles.radioButtonIconChecked} />
export const UncheckedRadioButton = <div style={styles.radioButtonIcon} />

const OptionsRadioGroup = (props, context) => {
  const { name, label, options, activeValue, onChange, disabled, prohibitTextTransform, hideLabel, row } = props

  const T = pathOr(F, ['intl', 'formatMessage'], context)

  const labelStyle = !isMaritime
    ? {
        color: '#404b50',
        fill: '#404b50',
      }
    : {
        color: '#000000',
        fill: '#000000',
        margin: '0 0 0 -10px',
        lineHeight: isMobile && getBluewaveStyle('20px'),
        fontSize: isMobile ? getBluewaveStyle('14px') : fontSizeStrong,
        bottom: isMobile && getBluewaveStyle('7px'),
      }

  return (
    <UILayout
      margin={getMaritimeStyle(row ? '10px 0 0 0' : '20px 0 0 0', '0')}
      column={!isMaritime}
      width="100%"
      position="relative"
      className={moduleStyles.formDirection}
    >
      <UIText
        display-if={!hideLabel}
        width={getMaritimeStyle('50%', 'auto')}
        align={getMaritimeStyle('left', 'center')}
        padding={getMaritimeStyle('8px 0 0 0', '0')}
        size={fontSizeStrong}
        color="#000000"
        textTransform="capitalize"
        text={label}
        className={moduleStyles.text}
        xs={{ size: getBluewaveStyle('13px'), width: getBluewaveStyle('80%') }}
      />
      <UILayout
        padding="0 0 2px 0"
        width={getMaritimeStyle('235px')}
        className={moduleStyles.formDirection}
        xs={{ width: getBluewaveStyle('200px') }}
      >
        <RadioButtonGroup
          name={name}
          valueSelected={activeValue}
          onChange={(event, value) => onChange(value)}
          style={{ ...styles.buttonGroupRoot, display: getMaritimeStyle(row ? 'flex' : '', 'block') }}
        >
          {map(
            ({ title, value, translation }) => (
              <RadioButton
                value={value}
                label={
                  prohibitTextTransform
                    ? (translation && T(translation)) || title
                    : toLower((translation && T(translation)) || title)
                }
                style={styles.radioButton}
                labelStyle={labelStyle}
                checkedIcon={CheckedRadioButton}
                uncheckedIcon={UncheckedRadioButton}
                disabled={disabled}
              />
            ),
            options
          )}
        </RadioButtonGroup>
      </UILayout>
    </UILayout>
  )
}

OptionsRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  activeValue: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

OptionsRadioGroup.defaultProps = {
  label: '',
  activeValue: '',
  disabled: false,
}

OptionsRadioGroup.contextTypes = {
  intl: PropTypes.any,
}

export default OptionsRadioGroup
