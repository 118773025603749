import { UILayout } from 'bora-material-ui'
import UIElements from '../consts/UIElements'
import PropTypes from 'prop-types'
import React from 'react'
import { isBluewave, isMaritime } from '../utils/maritimeStyleUtils'
import { withRouter } from 'react-router-dom'
import { THEME } from 'bora-material-ui/themes'
import { connect } from 'react-redux'
import { isEmpty, pathOr } from 'ramda'
import { compose } from 'redux'
import { shouldErrorBeIgnored } from '../modules/Booking/containers/MainInside/MainInsideView'
import { isWeightRequest } from '../reducers/network'
import { getPaymentErrorDetails, selectors } from '../modules/Booking/provider'
import { getErrorDetails } from '../services/reservation/selectors'

export const AppLayout = (props, { muiTheme }) => {
  const { errorDetails, isLastRequestAboutWeightChange, paymentError, reservationError } = props
  const errorOccurred =
    !shouldErrorBeIgnored(errorDetails, isLastRequestAboutWeightChange) && (paymentError || reservationError)

  const style = {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '20%',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    filter: props.isModalOpened && !errorOccurred ? 'blur(6px)' : 'none',
    ...muiTheme.ids.background,
  }

  const {
    location: { pathname = '' },
  } = props

  const getBg = () => {
    const isLiiniLaevad = window.brandProps.theme === THEME.LIINILAEVAD
    if (!isLiiniLaevad && !isBluewave) return UIElements.BACKGROUND

    try {
      const isMainPage = pathname.replaceAll('/', '').length < 3
      return isMainPage ? UIElements.BACKGROUND : UIElements.BACKGROUND_COLOR
    } catch (e) {
      return UIElements.BACKGROUND
    }
  }

  const isColor = (string) => {
    const colorSubstrings = ['#', 'rgb', 'rgba']
    return colorSubstrings.some((substring) => string.includes(substring))
  }

  const background = getBg()

  return (
    <UILayout
      data-test="app-layout"
      column
      center
      overflow="hidden"
      minHeight="100vh"
      position="relative"
      {...(isColor(background) ? { bgColor: background } : { bg: background })}
      style={style}
      className={`app-layout-wrapper${isMaritime ? '-maritime' : ''}`}
    >
      {props.children}
    </UILayout>
  )
}

AppLayout.contextTypes = {
  muiTheme: PropTypes.object,
}

AppLayout.propTypes = {
  children: PropTypes.any.isRequired,
}

export default compose(
  withRouter,
  connect((state) => {
    let redirectError

    if (window.URLSearchParams) {
      const searchParams = new URLSearchParams(location.search)

      if (searchParams.has('error')) {
        redirectError = searchParams.get('error')
      }
    }

    const isModalOpened = pathOr(false, ['modal'])(state)
    const paymentError = selectors.getPaymentError(state) || redirectError
    const reservationError = state.reservation.failed || false
    const isLastRequestAboutWeightChange = isWeightRequest(state)

    let errorDetails = getErrorDetails(state)
    if (isEmpty(errorDetails)) errorDetails = getPaymentErrorDetails(state)

    return {
      isModalOpened,
      paymentError,
      reservationError,
      errorDetails,
      isLastRequestAboutWeightChange,
    }
  }, null)
)(AppLayout)
