/* eslint-disable no-console */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { Fragment } from 'react'
import 'regenerator-runtime/runtime'
import 'react-hot-loader/patch'
import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import { ThemeProvider } from 'styled-components'
import { pathOr } from 'ramda'

import { AppContainer } from 'react-hot-loader'

import ReactDOM from 'react-dom'

import RootContainer from './rootContainer'
import { Helmet } from 'react-helmet'

const rootElementId = 'bora-app'
const theme = window.brandProps.muiTheme

const { env, appVersion, sentryDsn, enableSentry, mpayBaseUrl, adrumEnabled = false } = window.brandProps

const logBeacon = (stringData) => {
  try {
    // eslint-disable-next-line babel/no-unused-expressions
    navigator.sendBeacon && navigator.sendBeacon('/api/log-beacons', stringData)
  } catch (e) {
    console.warn('your browser is pretty old for sending beacons')
  }
}

window.onunload = () => {
  logBeacon('user left')
}

window.onload = () => {
  logBeacon('user joined')
}

Sentry.init({
  dsn: sentryDsn,
  environment: `${theme.name}-${env}`,
  release: appVersion,
  maxBreadcrumbs: 12,
  integrations: [new BrowserTracing()],
  attachStacktrace: false,
  enabled: enableSentry,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'console' && breadcrumb.message.startsWith('Warning: ')) {
      return null
    }
    if (breadcrumb.category === 'ui.click' && hint) {
      const { event } = hint

      let testId = ''
      if (event && 'data-test' in event.target.attributes) {
        testId = event.target.attributes['data-test'].value
      }
      if (event && 'data-testid' in event.target.attributes) {
        testId = event.target.attributes['data-testid'].value
      }

      if (testId) {
        breadcrumb.message = `${breadcrumb.message} [${testId}]`
      }
    }
    return breadcrumb
  },
})

const title = pathOr('', ['opengraph', 'name'])(window.brandProps)
const description = pathOr('', ['opengraph', 'description'])(window.brandProps)
const image = pathOr('', ['opengraph', 'image'])(window.brandProps)

const render = (Component) => {
  ReactDOM.render(
    <MuiThemeProvider muiTheme={getMuiTheme(theme)}>
      <ThemeProvider theme={{ breakpoints: theme.breakpoints }}>
        <Fragment>
          <Helmet>
            <meta name="description" content="Helmet application" />
            <meta property="og:image" content={image} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="twitter:image" content={image} />
          </Helmet>
          <AppContainer>
            <Component />
          </AppContainer>
        </Fragment>
      </ThemeProvider>
    </MuiThemeProvider>,
    document.getElementById(rootElementId)
  )
}

export const addScript = (src, attributes = {}) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = src
    Object.entries(attributes).forEach(([attributeName, attributeValue]) => {
      script.setAttribute(attributeName, attributeValue)
    })

    try {
      document.body.append(script)
    } catch (e) {
      console.error('addScript error', e)
    }

    script.onload = () => resolve(script)
    script.onerror = () => reject(new Error(`Impossible to load the script ${script.src}`))
  })

const run = async () => {
  if (adrumEnabled) {
    window['adrum-start-time'] = new Date().getTime()
    ;(function enableAppDynamics(config) {
      config.appKey = pathOr('', ['appDynamics', 'appKey'])(window.brandProps)
      config.adrumExtUrlHttp = pathOr('', ['appDynamics', 'extUrlHttp'])(window.brandProps)
      config.adrumExtUrlHttps = pathOr('', ['appDynamics', 'extUrlHttps'])(window.brandProps)
      config.beaconUrlHttp = pathOr('', ['appDynamics', 'beaconUrlHttp'])(window.brandProps)
      config.beaconUrlHttps = pathOr('', ['appDynamics', 'beaconUrlHttps'])(window.brandProps)
      config.xd = { enable: true }
    })(window['adrum-config'] || (window['adrum-config'] = {}))

    await addScript(pathOr('', ['appDynamics', 'scriptSrc'])(window.brandProps)).catch(console.warn)
  }
  if (mpayBaseUrl) {
    await addScript('https://code.jquery.com/jquery-3.6.0.min.js', {
      integrity: 'sha256-/xUj+3OJU5yExlq6GSYGSHk7tPXikynS7ogEvDej/m4=',
      crossorigin: 'anonymous',
    })
    await addScript(`${mpayBaseUrl}Scripts/ApplePayAPIDriven.min.js`).catch(console.warn)
    window.applePay.mPayAPIURL = mpayBaseUrl
  }
  if (document.getElementById(rootElementId) !== null) {
    render(RootContainer)
  }

  if (theme.browserTabTitle) {
    document.title = theme.browserTabTitle
  }
}

run().catch(console.error)

// eslint-disable-next-line
const browserIconLink = document.querySelector("link[rel~='icon']")
// eslint-disable-next-line
const appleIconLink = document.querySelector("link[rel~='apple-touch-icon']")

if (theme.customFavicon) {
  browserIconLink.href = `/images/${theme.customFavicon}/favicon.ico`
  appleIconLink.href = `/images/${theme.customFavicon}/apple-touch-icon.png`
} else {
  browserIconLink.href = '/images/favicon.ico'
  appleIconLink.href = '/images/apple-touch-icon.png'
}
if (module.hot) {
  module.hot.accept('./rootContainer', () => {
    // eslint-disable-next-line global-require
    const NextApp = require('./rootContainer').default
    render(NextApp)
  })
}
